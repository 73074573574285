import {
  Box,
  get as themeGet,
  type ThemeUIStyleObject,
  useThemeUI,
} from "theme-ui";
import type { OeComponentBase } from "./OeComponentBase";
import { type OceanExplorerTheme } from "theme";

interface IconSvgPathProps {
  color: string;
}

// We need the type def here to be compatible with `path`'s props
const commonIconProps: Record<string, string> = {
  fillRule: "evenodd",
  clipRule: "evenodd",
  alt: "string",
};

interface IconOption {
  width: number;
  height: number;
  alt: string;
  sx?: ThemeUIStyleObject;
  svgPath: (props: IconSvgPathProps) => JSX.Element;
}
// Select one or more SVGs in Figma, right click, Copy as SVG
const OeIconOptions: Record<string, IconOption> = {
  globe: {
    width: 73,
    height: 72,
    alt: "Globe",
    svgPath: (props: IconSvgPathProps) => (
      <path
        {...commonIconProps}
        d="M6.64813 33C7.76635 21.7374 15.1134 12.3071 25.1956 8.20278C20.6604 13.3895 16.2408 21.3862 15.5838 33H6.64813ZM6.64813 39C7.76635 50.2626 15.1134 59.6929 25.1956 63.7972C20.6604 58.6105 16.2408 50.6138 15.5838 39H6.64813ZM21.5947 39C22.2376 49.0135 26.0462 55.6724 29.7543 59.8956C31.0492 61.3703 32.3418 62.5584 33.5 63.4918V39H21.5947ZM39.5 39V63.4918C40.6582 62.5584 41.9508 61.3703 43.2457 59.8956C46.9538 55.6724 50.7624 49.0135 51.4053 39H39.5ZM51.4053 33H39.5V8.50824C40.6582 9.44156 41.9508 10.6297 43.2457 12.1044C46.9538 16.3276 50.7624 22.9865 51.4053 33ZM57.4162 39C56.7592 50.6138 52.3396 58.6105 47.8044 63.7972C57.8866 59.6929 65.2337 50.2626 66.3519 39H57.4162ZM66.3519 33H57.4162C56.7592 21.3862 52.3396 13.3895 47.8044 8.20278C57.8866 12.3071 65.2337 21.7374 66.3519 33ZM33.5 33H21.5947C22.2376 22.9865 26.0462 16.3276 29.7543 12.1044C31.0492 10.6297 32.3418 9.44156 33.5 8.50824V33ZM36.5 72C56.3823 72 72.5 55.8823 72.5 36C72.5 16.1177 56.3823 0 36.5 0C16.6177 0 0.5 16.1177 0.5 36C0.5 55.8823 16.6177 72 36.5 72Z"
        fill={props.color}
      />
    ),
  },
  "globe-plus": {
    width: 88,
    height: 88,
    alt: "Globe with a plus sign",
    svgPath: (props: IconSvgPathProps) => (
      <>
        <path
          {...commonIconProps}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75 0C74.4477 0 74 0.447716 74 1V8H67C66.4477 8 66 8.44772 66 9V13C66 13.5523 66.4477 14 67 14H74V21C74 21.5523 74.4477 22 75 22H79C79.5523 22 80 21.5523 80 21V14H87C87.5523 14 88 13.5523 88 13V9C88 8.44771 87.5523 8 87 8H80V1C80 0.447715 79.5523 0 79 0H75Z"
          fill={props.color}
        />
        <path
          {...commonIconProps}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.14813 49C7.26635 37.7374 14.6134 28.3071 24.6956 24.2028C20.1604 29.3895 15.7408 37.3862 15.0838 49H6.14813ZM6.14813 55C7.26635 66.2626 14.6134 75.6929 24.6956 79.7972C20.1604 74.6105 15.7408 66.6138 15.0838 55H6.14813ZM21.0947 55C21.7376 65.0135 25.5462 71.6724 29.2543 75.8956C30.5492 77.3703 31.8418 78.5584 33 79.4918V55H21.0947ZM39 55V79.4918C40.1582 78.5584 41.4508 77.3703 42.7457 75.8956C46.4538 71.6724 50.2624 65.0135 50.9053 55H39ZM50.9053 49H39V24.5082C40.1582 25.4416 41.4508 26.6297 42.7457 28.1044C46.4538 32.3276 50.2624 38.9865 50.9053 49ZM56.9162 55C56.2592 66.6138 51.8396 74.6105 47.3044 79.7972C57.3866 75.6929 64.7337 66.2626 65.8519 55H56.9162ZM65.8519 49H56.9162C56.2592 37.3862 51.8396 29.3895 47.3044 24.2028C57.3866 28.3071 64.7337 37.7374 65.8519 49ZM33 49H21.0947C21.7376 38.9865 25.5462 32.3276 29.2543 28.1044C30.5492 26.6297 31.8418 25.4416 33 24.5082V49ZM36 88C55.8823 88 72 71.8823 72 52C72 32.1177 55.8823 16 36 16C16.1177 16 0 32.1177 0 52C0 71.8823 16.1177 88 36 88Z"
          fill={props.color}
        />
      </>
    ),
  },
  "up-chevron": {
    width: 24,
    height: 24,
    alt: "up arrow",
    svgPath: (props: IconSvgPathProps) => (
      <g
        {...commonIconProps}
        stroke={props.color}
        fill="none"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="18 15 12 9 6 15"></polyline>
      </g>
    ),
  },
  "down-chevron": {
    width: 24,
    height: 24,
    alt: "down arrow",
    svgPath: (props: IconSvgPathProps) => (
      <g
        {...commonIconProps}
        stroke={props.color}
        fill="none"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </g>
    ),
  },
  "message-plus": {
    width: 88,
    height: 88,
    alt: "A rectangular speech bubble with a plus sign",
    svgPath: (props: IconSvgPathProps) => (
      <>
        <path
          {...commonIconProps}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75 0C74.4477 0 74 0.447716 74 1V8H67C66.4477 8 66 8.44772 66 9V13C66 13.5523 66.4477 14 67 14H74V21C74 21.5523 74.4477 22 75 22H79C79.5523 22 80 21.5523 80 21V14H87C87.5523 14 88 13.5523 88 13V9C88 8.44771 87.5523 8 87 8H80V1C80 0.447715 79.5523 0 79 0H75Z"
          fill={props.color}
        />
        <path
          {...commonIconProps}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.07394 29.0739C7.76157 28.3863 8.69421 28 9.66667 28H56.3333C57.3058 28 58.2384 28.3863 58.9261 29.0739C59.6137 29.7616 60 30.6942 60 31.6667V65C60 65.9725 59.6137 66.9051 58.9261 67.5927C58.2384 68.2804 57.3058 68.6667 56.3333 68.6667H16.3333C15.5377 68.6667 14.7746 68.9827 14.212 69.5453L6 77.7574V31.6667C6 30.6942 6.38631 29.7616 7.07394 29.0739ZM9.66667 22C7.10291 22 4.64415 23.0184 2.8313 24.8313C1.01845 26.6442 0 29.1029 0 31.6667V85C0 86.2134 0.730927 87.3073 1.85195 87.7716C2.97297 88.236 4.26333 87.9793 5.12132 87.1213L17.576 74.6667H56.3333C58.8971 74.6667 61.3558 73.6482 63.1687 71.8354C64.9815 70.0225 66 67.5638 66 65V31.6667C66 29.1029 64.9816 26.6442 63.1687 24.8313C61.3558 23.0184 58.8971 22 56.3333 22H9.66667ZM15 41H53V35H15V41ZM47 51H15V45H47V51ZM15 61H34V55H15V61Z"
          fill={props.color}
        />
      </>
    ),
  },
  "external-link": {
    width: 20,
    height: 22,
    alt: "A rectangular speech bubble with a plus sign",
    svgPath: (props: IconSvgPathProps) => (
      <g
        transform="translate(0,2)"
        fill="none"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          id="svg_1"
          d="m16.11018,10.95772l0,6a2,2 0 0 1 -2,2l-11,0a2,2 0 0 1 -2,-2l0,-11a2,2 0 0 1 2,-2l6,0"
        />
        <polyline
          id="svg_2"
          points="13.110180854797363,0.9577223062515259 19.11018180847168,0.9577223062515259 19.11018180847168,6.957722187042236 "
        />
        <line
          id="svg_3"
          y2="0.95772"
          x2="19.11018"
          y1="11.95772"
          x1="8.11018"
        />
      </g>
    ),
  },
};

export type OeIconName = keyof typeof OeIconOptions;
export const OeIconNames: OeIconName[] = Object.keys(
  OeIconOptions,
) as OeIconName[];
interface OeIconProps extends OeComponentBase {
  iconName: OeIconName;
  alt?: string; // override icon's default alt text
  width?: number;
  height?: number;
  variant?: keyof OceanExplorerTheme["icons"];
}

export function OeIcon(props: OeIconProps) {
  // Grab the theme
  const themeContext = useThemeUI();
  const theme = themeContext.theme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  const variant = props.variant ?? "primary";
  const selectedIcon = OeIconOptions[props.iconName];

  const width = props.width ?? selectedIcon.width;
  const height = props.height ?? selectedIcon.height;

  return (
    <Box
      sx={{
        width: width,
        height: height,
        color: themeGet(theme, `icons.${variant}.color`, "currentColor"),
        display: themeGet(theme, `icons.${variant}.display`),
        ...selectedIcon.sx,
        ...props.sx,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox={`0 0 ${selectedIcon.width} ${selectedIcon.height}`}
        role="img"
        preserveAspectRatio="xMidYMid meet"
      >
        <title>{props.alt ?? selectedIcon.alt}</title>
        <selectedIcon.svgPath color="currentColor" />
      </svg>
    </Box>
  );
}
