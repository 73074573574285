import { OeIcon } from "./OeIcon";
import type { OeComponentBase } from "./OeComponentBase";
import { useState, type ReactNode } from "react";
import type { OceanExplorerTheme } from "theme";
import {
  Box,
  Divider,
  Heading,
  useThemeUI,
  Flex,
  Button,
  IconButton,
} from "theme-ui";
import { If } from "./control-flow/If";

export interface OePanelProps extends OeComponentBase {
  children?: ReactNode;
  heading: string;
  isOpenByDefault?: boolean;
  /** Defaults to false */
  collapsible?: boolean;
  onClick?: (isOpen: boolean) => void;
}
export function OePanel(props: OePanelProps) {
  const collapsible = props.collapsible ?? false;

  // The Panel should be closed by default, unless:
  //  - It's not collapsible
  //  - isOpenByDefault is true
  const [isOpen, setIsOpen] = useState(
    !collapsible || (props.isOpenByDefault ?? false),
  );
  // Grab the theme
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  const onClick = () => {
    if (collapsible) {
      setIsOpen(!isOpen);
    }
    if (props.onClick !== undefined) {
      props.onClick(true);
    }
  };

  return (
    <Box
      variant="boxes.sectionRounded"
      sx={{
        paddingTop: 0,
        ...props.sx,
        position: "relative",
      }}
    >
      <Box
        onClick={onClick}
        sx={{
          cursor: collapsible ? "pointer" : "inherit",
          top: 0,
          left: 0,
          right: 0,
          bottom: "0%",
          zIndex: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ...theme.panels.primary.header,
        }}
      >
        <Heading as="h3" variant={"styles.h3"}>
          {props.heading}
        </Heading>
        <If condition={collapsible}>
          <IconButton sx={{ width: "auto", height: "auto" }}>
            <OeIcon
              width={24}
              height={24}
              iconName={isOpen ? "down-chevron" : "up-chevron"}
            />
          </IconButton>
        </If>
      </Box>
      <If condition={isOpen}>
        <Divider sx={{ marginTop: 0 }} />
        <Box sx={theme.panels.primary.body}>{props.children}</Box>
      </If>
    </Box>
  );
}
