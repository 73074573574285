import { PageContainer } from "src/components/PageContainer";
import {
  Heading,
  Text,
  Link as ThemeUILink,
  NavLink as ThemeUINavLink,
  Box,
  Button,
  useThemeUI,
} from "theme-ui";
import { LandingPageRoute } from "./LandingPage";
import { Link as HashLink } from "react-router-dom";
import { PrivacyPageRoute } from "./PrivacyPage";
import { OeLink, type OceanExplorerTheme } from "oex-common-ui";

export const ContactPageRoute = "/home/contact";
export const ContactPageTitle = "Contact";

export const ContactPage = () => {
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;

  return (
    <PageContainer>
      <Heading as="h2" variant={"styles.h2"}>
        {ContactPageTitle}
      </Heading>
      <Text as="p" variant={"styles.p"} sx={{ fontSize: 4 }}>
        <Text variant="secondary">
          Immersive Data Insights (IDI) is an initiative of
        </Text>{" "}
        <Text> CSIRO&apos;s Data61</Text>
        <Text variant="secondary">
          . Please get in touch with any questions, comments, feedback, or just
          to say hello 👋
        </Text>
      </Text>

      <Text as="p" variant="muted" sx={{ fontSize: 4 }}>
        Email
      </Text>

      <Text as="p" variant={"styles.p"} sx={{ fontSize: 3 }}>
        <OeLink
          sx={{
            color: theme.colors.primary,
          }}
          href="mailto:immersivedata@internal.csiro.au"
        >
          immersivedata@internal.csiro.au
        </OeLink>
      </Text>
    </PageContainer>
  );
};
