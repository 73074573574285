import { OeLink, type OceanExplorerTheme } from "oex-common-ui";
import type { ReactNode } from "react";
import React from "react";
import { Link as HashLink } from "react-router-dom";
import { LandingPageRoute } from "src/pages/LandingPage";
import { PAGE_LIST } from "src/pages/PageList";
import { PricingPageRoute } from "src/pages/PricingPage";
import { Box, Flex, Heading, Paragraph, Text, useThemeUI } from "theme-ui";

interface PageContainerProps {
  children: ReactNode;
}

export const PageContainer = (props: PageContainerProps) => {
  const themeContext = useThemeUI();
  const theme = themeContext.theme as OceanExplorerTheme;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          top: 0,
          padding: "32px",
          marginBottom: "32px",
          borderBottomColor: "vars.border.section",
          borderBottomStyle: "solid",
          borderBottomWidth: 0,
        }}
      >
        {/* Header */}
        <Heading as="h1" variant={"styles.h1"}>
          <Flex
            sx={{
              justifyContent: "flex-start",
            }}
          >
            <OeLink
              Component={HashLink}
              to={LandingPageRoute}
              sx={{
                textDecoration: "none",
              }}
              variant="nav"
            >
              Immersive Data Insights (IDI)
            </OeLink>

            <OeLink
              variant="colored"
              sx={{ marginLeft: "0.5em" }}
              href="https://www.productplan.com/glossary/beta-test/"
            >
              Beta
            </OeLink>

            <OeLink
              Component={HashLink}
              to={PricingPageRoute}
              variant="nav"
              sx={{
                marginLeft: "auto",
              }}
            >
              <Text>Work with us</Text>
            </OeLink>
          </Flex>
        </Heading>
      </Box>

      {/* Main content */}
      <Box
        sx={{
          maxWidth: "1320px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingY: "40px",
          paddingX: "40px",
        }}
      >
        {props.children}
      </Box>

      {/* Footer */}
      <Flex
        sx={{
          textAlign: "left",
          padding: "32px",

          borderTopColor: "vars.border.section",
          borderTopStyle: "solid",
          borderTopWidth: 0,

          marginTop: "32px",
          marginBottom: "32px",

          gap: "10%",
        }}
      >
        {/* Contact info */}
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Heading
            as="h3"
            sx={{
              paddingBottom: "12px",
            }}
          >
            Immersive Data Insights (IDI)
          </Heading>

          <Flex
            sx={{
              alignContent: "space-between",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Paragraph>
              <Text variant="muted">CSIRO&apos;s Data61</Text> <br />
              <Text variant="muted" sx={{ fontWeight: "light" }}>
                Sydney + Melbourne, Australia
              </Text>
            </Paragraph>

            <Paragraph>
              <OeLink
                sx={{
                  color: theme.colors.primary,
                  textDecoration: "none",
                }}
                href="mailto:immersivedata@internal.csiro.au"
              >
                immersivedata@internal.csiro.au
              </OeLink>
            </Paragraph>
          </Flex>
        </Flex>

        {/* Site pages */}
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "start",
            gap: "6px",
          }}
        >
          {PAGE_LIST.map(({ route, title }, idx) => {
            if (title !== undefined) {
              return (
                <OeLink
                  key={route}
                  to={route}
                  Component={HashLink}
                  variant="nav"
                  sx={{
                    color: "vars.text.secondary",
                    fontSize: 2,
                    "&:hover": {
                      color: "vars.text.primary",
                      textDecoration: "underline",
                    },
                  }}
                >
                  {title}
                </OeLink>
              );
            }
            return <React.Fragment key={idx}></React.Fragment>;
          })}
        </Flex>
      </Flex>
    </>
  );
};
