import { createRoot } from "react-dom/client";
import { Switch, ThemeUIProvider } from "theme-ui";
import { HashRouter, Routes, Route } from "react-router-dom";
import { homeTheme } from "./homeTheme";
import { LandingPage } from "./pages/LandingPage";
import {
  PublicDataRequestPage,
  PublicDataRequestPageRoute,
} from "./pages/PublicDataRequest/PublicDataRequestPage";
import { PrivacyPage, PrivacyPageRoute } from "./pages/PrivacyPage";
import { ContactPage, ContactPageRoute } from "./pages/ContactPage";
import { PricingPage, PricingPageRoute } from "./pages/PricingPage";
import {
  DataCataloguePage,
  DataCataloguePageRoute,
} from "./pages/DataCataloguePage";
import { PAGE_LIST } from "./pages/PageList";

const HomeApp = () => {
  return (
    <ThemeUIProvider theme={homeTheme}>
      <HashRouter>
        <Routes>
          {/* <Route path={PrivacyPageRoute} Component={PrivacyPage} />
          <Route path={ContactPageRoute} Component={ContactPage} />
          <Route
            path={PublicDataRequestPageRoute}
            Component={PublicDataRequestPage}
          />
          <Route path={PricingPageRoute} Component={PricingPage} />
          <Route path={DataCataloguePageRoute} Component={DataCataloguePage} />
          <Route path="/" Component={LandingPage} /> */}
          {PAGE_LIST.map(({ route, PageComponent }) => (
            <Route key={route} path={route} Component={PageComponent} />
          ))}
        </Routes>
      </HashRouter>
    </ThemeUIProvider>
  );
};
export default HomeApp;

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(<HomeApp />);
