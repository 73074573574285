import {
  PublicDataRequestPage,
  PublicDataRequestPageRoute,
} from "./PublicDataRequest/PublicDataRequestPage";
import { PrivacyPage, PrivacyPageRoute, PrivacyPageTitle } from "./PrivacyPage";
import { ContactPage, ContactPageRoute, ContactPageTitle } from "./ContactPage";
import { PricingPage, PricingPageRoute, PricingPageTitle } from "./PricingPage";
import {
  DataCataloguePage,
  DataCataloguePageRoute,
  DataCataloguePageTitle,
} from "./DataCataloguePage";
import { type ComponentType } from "react";
import { LandingPage, LandingPageRoute, LandingPageTitle } from "./LandingPage";

interface Page {
  PageComponent: ComponentType;
  route: string;
  title?: string;
}

// If a page doesn't have a title, it won't appear in the footer — see
// PageContainer
export const PAGE_LIST: Page[] = [
  {
    PageComponent: ContactPage,
    route: ContactPageRoute,
    title: ContactPageTitle,
  },
  {
    PageComponent: PrivacyPage,
    route: PrivacyPageRoute,
    title: PrivacyPageTitle,
  },
  {
    PageComponent: DataCataloguePage,
    route: DataCataloguePageRoute,
    title: DataCataloguePageTitle,
  },
  {
    PageComponent: PricingPage,
    route: PricingPageRoute,
  },
  {
    PageComponent: LandingPage,
    route: LandingPageRoute,
  },
  { PageComponent: PublicDataRequestPage, route: PublicDataRequestPageRoute },
];
