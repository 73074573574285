import { OeLink } from "oex-common-ui";
import { Link as HashLink } from "react-router-dom";
import { PageContainer } from "src/components/PageContainer";
import { Button, Heading, Paragraph, Text } from "theme-ui";
import { ContactPageRoute } from "./ContactPage";

export const PricingPageRoute = "/home/pricing";
export const PricingPageTitle = "Work with us";

export const PricingPage = () => {
  return (
    <PageContainer>
      {/* h1 is 32px, and h2 is 24px */}
      <Heading as="h1" sx={{ fontSize: "30px", paddingBottom: "16px" }}>
        {PricingPageTitle}
      </Heading>
      <Paragraph>
        <Text variant="secondary" sx={{ fontSize: 5, fontWeight: "heading" }}>
          We’re here to help you explore your volumetric data in new and
          intuitive ways. Whether you need to visualise one dataset or build
          custom tools, we’ll work together to reveal insights and share them in
          a compelling way.
        </Text>
      </Paragraph>
      <table style={{ borderSpacing: "0px 80px" }}>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "top", paddingRight: "80px" }}>
              <Heading as="h3" sx={{ marginBottom: "24px" }}>
                <Text variant="muted">Tier 1</Text>
              </Heading>
              <Heading as="h2">Scoping Study</Heading>

              <Text variant="muted" sx={{ fontSize: 2, lineHeight: "24px" }}>
                <Paragraph sx={{ padding: "8px 0px" }}>
                  Explore your challenges, set goals and outcomes, and clarify
                  potential next steps.
                </Paragraph>
                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text> Activities summary: </Text>
                  Expert interviews and a 2-hour workshop to document and
                  understand your needs.
                </Paragraph>

                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text>Key Deliverables: </Text>A recommendations report that
                  includes an assessment of your requirements, and your next
                  steps.
                </Paragraph>

                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text>Our Effort: </Text>3 FTE x 2.5 days
                </Paragraph>

                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text>Your effort: </Text>
                  1-hour preparation interviews for everyone involved, 2.5-hour
                  workshop participation and provision of any relevant technical
                  documentation along with follow ups to technical questions.
                </Paragraph>

                <Paragraph sx={{ paddingTop: "8px" }}>
                  <Text>Duration: </Text>1 week
                </Paragraph>
              </Text>
            </td>

            <td style={{ verticalAlign: "top" }}>
              <Heading as="h3" sx={{ marginBottom: "24px" }}>
                <Text variant="muted">Tier 2</Text>
              </Heading>
              <Heading as="h2">Proof of concept</Heading>

              <Text variant="muted" sx={{ fontSize: 2, lineHeight: "24px" }}>
                <Paragraph sx={{ padding: "8px 0px" }}>
                  For public and custom datasets that we do not currently
                  support, we can create a visualisation as a proof of concept.
                </Paragraph>
                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text> Activities summary: </Text>
                  We’ll prepare, upload and visualise your data on the IDI
                  platform, providing access to interactive 3D visualisations.
                </Paragraph>
                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text>Key Deliverables: </Text>A fully visualised dataset
                  ready for exploration and sharing with collaborators.
                </Paragraph>
                <Paragraph sx={{ paddingTop: "8px" }}>
                  <Text>Our Effort, Your Effort & Duration: </Text>
                  0.5 FTE x 2 months
                </Paragraph>
                as set out in scoping study.
              </Text>
            </td>
          </tr>

          <tr>
            <td style={{ verticalAlign: "top", paddingRight: "80px" }}>
              <Heading as="h3" sx={{ marginBottom: "24px" }}>
                <Text variant="muted">Tier 3</Text>
              </Heading>
              <Heading as="h2">Co-designed prototype</Heading>

              <Text variant="muted" sx={{ fontSize: 2, lineHeight: "24px" }}>
                <Paragraph sx={{ padding: "8px 0px" }}>
                  Co-design a custom solution that fits your needs.
                </Paragraph>
                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text> Activities summary: </Text>A series of workshops with
                  your team to identify requirements and co-create a custom
                  concept.
                </Paragraph>

                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text>Key Deliverables: </Text>
                  An early prototype of your custom requirements, along with a
                  plan for implementation.
                </Paragraph>

                <Paragraph sx={{ paddingTop: "8px" }}>
                  <Text>Our Effort, Your Effort & Duration: </Text>
                  as determined during proof of concept.
                </Paragraph>
              </Text>
            </td>

            {/* This one didn't align at the top for some reason
                but I added it to all of them
              */}
            <td style={{ verticalAlign: "top" }}>
              <Heading as="h3" sx={{ marginBottom: "24px" }}>
                <Text variant="muted">Tier 4</Text>
              </Heading>
              <Heading as="h2">Tailored solution</Heading>

              <Text variant="muted" sx={{ fontSize: 2, lineHeight: "24px" }}>
                <Paragraph sx={{ padding: "8px 0px" }}>
                  Develop a fully custom solution built based on your
                  requirements.
                </Paragraph>
                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text>Activities summary: </Text>
                  from a new plug-in to new features tailored to your needs, we
                  build it based on your requirements, leveraging iterative
                  design, coding, and testing.
                </Paragraph>

                <Paragraph sx={{ padding: "8px 0px" }}>
                  <Text>Key Deliverables: </Text>
                  Bespoke solution, including full support and integration into
                  your workflow.
                </Paragraph>

                <Paragraph sx={{ paddingTop: "8px" }}>
                  <Text>Our Effort, Your Effort & Duration: </Text>
                  as determined during co-design process.
                </Paragraph>
              </Text>
            </td>
          </tr>
        </tbody>
      </table>

      <OeLink Component={HashLink} to={ContactPageRoute} variant="nav">
        <Button
          variant="call-to-action-primary"
          sx={{
            fontSize: 4,
            padding: "24px 48px",
            lineHeight: "0px",
          }}
        >
          Contact Us
        </Button>
      </OeLink>
    </PageContainer>
  );
};
