import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  get as themeGet,
  useThemeUI,
} from "theme-ui";
import type { OeComponentBase } from "./OeComponentBase";
import type { OeIconName } from "./OeIcon";
import { OeIcon } from "./OeIcon";
import type { OeLink } from "./OeLink";

// TODO: document prop defaults, add instructions for links
export interface OeThumbnailProps extends OeComponentBase {
  imageSrc?: string;
  headingText?: string;
  text?: string;
  iconName?: OeIconName;
  alt?: string;
  LinkComponent: typeof OeLink;
}

export const OeThumbnail = (props: OeThumbnailProps) => {
  // Grab the theme
  const themeContext = useThemeUI();
  const theme = themeContext.theme;
  if (Object.getOwnPropertyNames(theme).length <= 0) {
    // Theme not initialized yet.
    return <></>;
  }

  // @ts-expect-error sx should allow any css prop, I don't know why this is wrong
  let width = props.sx?.width;
  // @ts-expect-error ref to above expect-error
  let height = props.sx?.height;

  const backgroundColor = themeGet(
    theme.rawColors!,
    themeGet(theme, "images.thumbnail.backgroundColor"),
  );

  const LinkComponent = props.LinkComponent;

  const thumbnailMargin = 5;
  const thumbnailContentsStyles = {
    marginBottom: 5,
    width: width,
    height: height,
    borderRadius: 2,
  };

  let ThumbnailContents = () => <></>;
  if (props.imageSrc !== undefined) {
    // Put an image in the thumbnail
    ThumbnailContents = function ThumbnailContents() {
      return (
        <Image
          variant="thumbnail"
          src={props.imageSrc}
          sx={{ ...thumbnailContentsStyles, width, height }}
          alt={props.alt}
        ></Image>
      );
    };
  } else {
    // Instead of using an image, use an icon, and center it in the thumbnail
    // box.
    // We need to make sure the width and height are specified if there's no
    // image to do it for us. The magic numbers come from Figma.
    width = width ?? 457;
    height = height ?? 257;
    ThumbnailContents = function ThumbnailContents() {
      return (
        <Flex
          role="img"
          sx={{
            ...thumbnailContentsStyles,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: backgroundColor,
            width,
            height,
          }}
        >
          {props.iconName && <OeIcon iconName={props.iconName} />}
        </Flex>
      );
    };
  }

  let TextBox = () => <></>;

  if (props.headingText !== undefined || props.text !== undefined) {
    TextBox = function TextBox() {
      return (
        <>
          {props.headingText && (
            // if we have a heading
            <Heading
              variant="h3"
              sx={{
                marginBottom: 3,
                ":hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {LinkComponent !== undefined ? (
                <LinkComponent>{props.headingText}</LinkComponent>
              ) : (
                <>{props.headingText}</>
              )}
            </Heading>
          )}
          {props.text && (
            // if we have text
            <Text
              variant="secondary"
              sx={{
                fontSize: 1,
              }}
            >
              {props.text}
            </Text>
          )}
        </>
      );
    };
  }

  return (
    <Box
      sx={{
        marginTop: thumbnailMargin,
        marginBottom: thumbnailMargin,
        ...props.sx,
      }}
    >
      {LinkComponent !== undefined ? (
        <LinkComponent>
          <ThumbnailContents />
        </LinkComponent>
      ) : (
        <ThumbnailContents />
      )}
      <TextBox />
    </Box>
  );
};
