import { cloneDeep, omit } from "lodash";
import React from "react";
import { type OceanExplorerTheme } from "theme";
import { css as cssThemeUI, Link, useThemeUI } from "theme-ui";
import { type OeComponentBase } from "./OeComponentBase";
import { css } from "@emotion/css";
import { OeIcon } from "./OeIcon";

type SomeComponent = React.ComponentType<{
  className?: string;
}> | null;

/**
 * Props for the OeLink component.
 *
 * @template L the type of a component to optionally render the Link as
 */
export interface OeLinkProps<L extends SomeComponent> extends OeComponentBase {
  /** The content to be rendered inside the link, eg. text*/
  children?: React.ReactNode;
  /** Will be ignored if `component` is provided */
  href?: string;
  /** If provided, then OeLink will be rendered as Component, with the ThemeUI styles applied */
  Component?: L;
  /** Theme UI link variant */
  target?: "_blank" | "_self" | "_parent" | "_top" | "_unfencedTop";
  variant?: keyof OceanExplorerTheme["links"]; // defaults to "primary"
  /** `variant` prop to be passed to `Component`. Needed to distinguish Component's variant from OeLink's `variant`. */
  componentVariant?: string;
}

/**
 * If a `Component` prop is provided, OeLink will render that component with
 * ThemeUI styling. Otherwise, it'll render a regular ThemeUI link.
 */
export const OeLink: React.FC<any> = <L extends SomeComponent>(
  props: OeLinkProps<L>,
) => {
  let variant = props.variant;

  // Default to `external` variant for external links
  if (variant === undefined && props.href?.startsWith("https")) {
    variant = "external";
  }

  // If a link component to render as has been provided, use that
  const Component = props.Component;
  if (Component != undefined && Component != null) {
    const applyLinkTheme = props.componentVariant ?? true;
    let linkStyles = undefined;
    if (applyLinkTheme) {
      // We have to "resolve" the link theme into regular css before we give it to the
      // Component, since it might not be compatible with ThemeUI

      // Grab the theme
      const themeContext = useThemeUI();
      const theme = themeContext.theme as OceanExplorerTheme;
      if (Object.getOwnPropertyNames(theme).length <= 0) {
        // Theme not initialized yet.
        return <></>;
      }

      const linkThemeVariant =
        variant !== undefined
          ? cloneDeep(theme.links[variant])
          : cloneDeep(theme.styles.a);
      linkStyles = cssThemeUI({ ...linkThemeVariant, ...props.sx })(theme);
    }

    // We remove the props that are specific to OeLink before we pass them to
    // Component
    const linkComponentProps = omit(props, [
      "variant",
      "Component",
      "componentVariant",
    ]) as any;
    return (
      <Component
        {...linkComponentProps}
        variant={props.componentVariant}
        className={linkStyles !== undefined ? css(linkStyles) : undefined}
      />
    );
  }

  // otherwise, use the regular ThemeUI link
  return (
    <Link
      variant={variant}
      sx={{ ...props.sx }}
      href={props.href}
      target={props.target}
    >
      {props.children}
      {(variant === "external" || variant === "externalColored") &&
      typeof props.children === "string" ? (
        <>
          <OeIcon
            sx={{ paddingLeft: "4px" }}
            width={20}
            height={16}
            variant="inline"
            iconName="external-link"
          />
        </>
      ) : (
        <></>
      )}
    </Link>
  );
};
