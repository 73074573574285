import { OeLink, OeThumbnail } from "oex-common-ui";
import { PageContainer } from "src/components/PageContainer";
import { Flex, Heading, Text } from "theme-ui";
import { PublicDataRequestPageRoute } from "./PublicDataRequest/PublicDataRequestPage";
import { OeLinkProps } from "oex-common-ui/lib/OeLink";
import { Link as HashLink } from "react-router-dom";

export const DataCataloguePageRoute = "/home/catalogue";
export const DataCataloguePageTitle = "Data Catalogue";

export const DataCataloguePage = () => {
  return (
    <PageContainer>
      <Heading
        sx={{
          marginBottom: "24px",
        }}
      >
        <Text variant="secondary">Data Catalogue</Text>
      </Heading>
      <Flex
        sx={{
          flexDirection: "row",
          gap: "24px",
          justifyContent: "start",
        }}
      >
        <OeThumbnail
          headingText="Explore public catalogue"
          text="Explore a curated catalogue of public data services"
          iconName="globe-plus"
          LinkComponent={(props: { children?: React.ReactNode }) => (
            <OeLink Component={HashLink} to={PublicDataRequestPageRoute}>
              {props.children}
            </OeLink>
          )}
        />

        <OeThumbnail
          headingText="Request a new dataset"
          text="Let us know which datasets you want to see on the IDI platform"
          iconName="message-plus"
          LinkComponent={(props: { children?: React.ReactNode }) => (
            <OeLink href={"https://forms.office.com/r/ZqerbDr45y"}>
              {props.children}
            </OeLink>
          )}
        />
      </Flex>
    </PageContainer>
  );
};
